import { ResonanceDates } from "../../global";
import { DemandHqId } from "../demand";

import { SupplyEntityIdPrefix } from "./SupplyEntity";
import { FulfillmentTrackingId, SupplierId } from "./SupplyIds";

export const buildFulfillmentTrackingId = (
    trackingNumber: string,
): FulfillmentTrackingId => {
    return `${SupplyEntityIdPrefix.FulfillmentTracking}-${trackingNumber}`;
};

export interface FulfillmentTrackingIdFields {
    fulfillmentTrackingId: FulfillmentTrackingId;
}

export interface FulfillmentTrackingRelationshipFields {
    demandHqId: DemandHqId;
    supplierId: SupplierId;
    // May span multiple POIs, OIs, and even Orders.
}

export interface FulfillmentTrackingLocation {
    city: string | null | undefined;
    country: string | null | undefined;
    state: string | null | undefined;
    zip: string | null | undefined;
}

export interface FulfillmentTrackingServiceLevel {
    code: string;
    name: string;
}

export type FulfillmentTrackingStatus =
    | "UNKNOWN"
    | "DELIVERED"
    | "PRE_TRANSIT"
    | "TRANSIT"
    | "FAILURE"
    | "RETURNED";

export interface FulfillmentTrackingStatusItem {
    location: FulfillmentTrackingLocation;
    status: FulfillmentTrackingStatus;
    statusDate: string;
    statusDetails: string;
}

export interface FulfillmentTracking
    extends FulfillmentTrackingIdFields,
        FulfillmentTrackingRelationshipFields,
        Pick<ResonanceDates, "updatedAt"> {
    eta: string;
    locationFrom?: FulfillmentTrackingLocation | null;
    locationTo?: FulfillmentTrackingLocation | null;
    originalEta: string;
    serviceLevel: FulfillmentTrackingServiceLevel;
    shippingCarrier: string;
    statusHistory: FulfillmentTrackingStatusItem[];
}

export type FulfillmentTrackingModelCreateInput = Omit<
    FulfillmentTracking,
    keyof ResonanceDates
>;

/** Only full updates, as statusHistory is so important to keep consistent anyway */
export type FulfillmentTrackingModelUpdateInput = Omit<
    FulfillmentTracking,
    keyof FulfillmentTrackingRelationshipFields | keyof ResonanceDates
>;

export type PublicFulfillmentTracking = Omit<
    FulfillmentTracking,
    keyof FulfillmentTrackingRelationshipFields
>;
